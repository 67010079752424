import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  list: [],
};

export const promocodeSlice = createSlice({
  name: "promocode",
  initialState,
  reducers: {
    addPromocode: (state, action) => {
      const inWishlist = state.list.find(
        (item) => item.id === action.payload.id
      );

      if (!inWishlist) {
        state.list.push({
          ...action.payload,
        });
      }
    },

    removePromocode: (state, action) => {
      const inWishlist = state.list.find(
        (item) => item.id === action.payload.id
      );

      if (inWishlist) {
        state.list = state.list.filter((item) => item.id !== action.payload.id);
      }
    },
    resetPromocodes: (state) => {
      state.list = [];
    },
  },
});

export const { addPromocode, removePromocode, resetPromocodes } =
  promocodeSlice.actions;
