import { OrderItem } from "./OrderItem";
import { ReviewItem } from "./ReviewItem";
import { ProductCard } from "./ProductCard";
import { ProfileItem } from "./ProfileItem";
import { WishlistItem } from "./WishlistItem";
import { PromocodeItem } from "./PromocodeItem";

export const items = {
  OrderItem,
  ReviewItem,
  ProductCard,
  ProfileItem,
  WishlistItem,
  PromocodeItem,
};
