import { createSlice } from "@reduxjs/toolkit";

const initialState = { phoneVerified: false, emailVerified: false };

export const verificationSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setPhoneVerified: (state, action) => {
      state.phoneVerified = action.payload;
    },
    setEmailVerified: (state, action) => {
      state.emailVerified = action.payload;
    },
  },
});

export const { setPhoneVerified, setEmailVerified } = verificationSlice.actions;
