// const MAIN_URL = "https://george-fx.github.io/stephub/";
export const MAIN_URL = "https://catalogmakerpro.in/server/CatalogMakerPro";

export const GET_TAGS = `${MAIN_URL}api/tags.json`;
export const GET_ORDERS = `${MAIN_URL}api/orders.json`;
export const GET_REVIEWS = `${MAIN_URL}api/reviews.json`;
export const GET_BANNERS = `${MAIN_URL}api/banners.json`;
// export const GET_CAROUSEL = `${MAIN_URL}api/carousel.json`;
// export const GET_PRODUCTS = `${MAIN_URL}api/products.json`;
export const GET_CAROUSEL = `${MAIN_URL}/pro-web/banners/getBanners.php`;
export const GET_PRODUCTS = `${MAIN_URL}/getCategoriesAndproducts.php`;
export const GET_COMPANY = `${MAIN_URL}/getcompanyprofile.php`;
export const GET_AUDIENCES = `${MAIN_URL}api/audiences.json`;
export const GET_PROMOCODES = `${MAIN_URL}api/promocodes.json`;
export const GET_CATEGORIES = `${MAIN_URL}api/categories.json`;

export const URLS = {
  GET_TAGS,
  GET_ORDERS,
  GET_BANNERS,
  GET_REVIEWS,
  GET_CAROUSEL,
  GET_PRODUCTS,
  GET_AUDIENCES,
  GET_PROMOCODES,
  GET_CATEGORIES,
  GET_COMPANY,
};
