import React from "react";

export const ImageBackground = ({ children, imageUrl, style }) => {
  return (
    <div
      style={{
        display: "flex",
        position: "relative",
        flexDirection: "column",
        backgroundImage: `url(${imageUrl})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        ...style,
      }}
    >
      {children}
    </div>
  );
};
