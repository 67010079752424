import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  cvv: "",
  name: "",
  address: "",
  expiryDate: "",
  cardNumber: "",
  cardHolderName: "",
};

export const paymentSlice = createSlice({
  name: "payment",
  initialState,
  reducers: {
    setName: (state, action) => {
      state.name = action.payload;
    },
    setAddress: (state, action) => {
      state.address = action.payload;
    },
    setCardNumber: (state, action) => {
      state.cardNumber = action.payload;
    },
    setExpiryDate: (state, action) => {
      state.expiryDate = action.payload;
    },
    setCvv: (state, action) => {
      state.cvv = action.payload;
    },
    setCardHolderName: (state, action) => {
      state.cardHolderName = action.payload;
    },
  },
});

export const {
  setName,
  setAddress,
  setCardNumber,
  setExpiryDate,
  setCvv,
  setCardHolderName,
} = paymentSlice.actions;

export default paymentSlice.reducer;
