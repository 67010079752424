import React from "react";
import { useNavigate } from "react-router-dom";
import { hooks } from "../hooks";
import { theme } from "../constants";
import { actions } from "../store/actions";

export const BottomTabBar = () => {
  const dispatch = hooks.useAppDispatch();
  const navigate = useNavigate();
  const cart = hooks.useAppSelector((state) => state.cartSlice.list);
  const { list } = hooks.useAppSelector((state) => state.cartSlice);
  const totalQuantity = list.reduce((total, product) => {
    return total + product.quantity;
  }, 0);

  const currentTabScreen = hooks.useAppSelector(
    (state) => state.tabSlice.screen
  );

  const handleOnClick = () => {
    if (cart?.length > 0) {
      dispatch(actions.setScreen("Order"));
      navigate("/order");
      return;
    }

    return alert("Your cart is empty");
  };

  return (
    <nav
      style={{
        height: 80,
        bottom: 0,
        position: "fixed",
        zIndex: 4,
        width: "100%",
        maxWidth: 768,
        backgroundColor: theme.colors.white,
        boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
      }}
    >
      <div
        onClick={handleOnClick}
        style={{
          flexDirection: "row",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-around",
          height: "75%",
          position: "relative",
          backgroundColor: theme.colors.mainColor,
          margin: 10,
          borderRadius: 50,
        }}
      >
        <div>
          <h6
            style={{
              ...theme.fonts.Mulish_400Regular,
              fontSize: 14,
              lineHeight: 1.5,
              margin: 0,
              color: theme.colors.white,
              fontWeight: 400,
              marginBottom: 4,
            }}
          >
            View Cart ( {totalQuantity} )
          </h6>
        </div>
      </div>
    </nav>
  );
};
