import { Line } from "./Line";
import { Header } from "./Header";
import { Button } from "./Button";
import { Loader } from "./Loader";
import { TabLoader } from "./TabLoader";
import { RatingStars } from "./RatingStars";
import { BlockHeading } from "./BlockHeading";
import { BurgerMenuItem } from "./BurgerMenuItem";
import { ProfileItem } from "../items/ProfileItem";
import { BottomTabBar } from "../navigation/BottomTabBar";

export const components = {
  Line,
  Header,
  Button,
  Loader,
  TabLoader,
  ProfileItem,
  RatingStars,
  BottomTabBar,
  BlockHeading,
  BurgerMenuItem,
};
