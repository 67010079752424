// App.js
import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Home } from "./screens/tabs/Home";
import { Order } from "./screens/tabs/Order";
import { Product } from "./screens/tabs/Product";
import { ShippingAndPaymentInfo } from "./screens/tabs/ShippingAndPaymentInfo";
import { Shop } from "./screens/tabs/Shop";
import { OrderSuccessful } from "./screens/tabs/OrderSuccessful";
function App() {
  return (
    <div className="container" style={{ height: "100vh" }}>
      <BrowserRouter>
        <Routes>
          <Route path="/:id" element={<Home />} />
          <Route path="/order" element={<Order />} />
          <Route path="/product" element={<Product />} />
          <Route
            path="/ShippingAndPaymentInfo"
            element={<ShippingAndPaymentInfo />}
          />
          <Route path="/Shop" element={<Shop />} />
          <Route path="/OrderSuccessful" element={<OrderSuccessful />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
