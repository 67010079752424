import { createSlice } from "@reduxjs/toolkit";

const initialState = { user: null, rememberMe: false };

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
    },
    logOut: (state) => {
      state.user = null;
      state.rememberMe = false;
    },
    setRememberMe: (state, action) => {
      state.rememberMe = action.payload;
    },
  },
});

export const { setUser, logOut, setRememberMe } = userSlice.actions;

export { userSlice };
