import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  total: 0,
  list: [],
  delivery: 0,
  discount: 0,
  subtotal: 0,
  promoCode: "",
  discountAmount: 0,
};

export const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    addToCart: (state = initialState, action) => {
      const inCart = state.list.find((item) => item.id === action.payload.id);

      if (inCart) {
        state.list.map((item) => {
          if (item.id === action.payload.id) {
            if (item.quantity) {
              item.quantity += 1;
            }
          }
          return item;
        }, state);
        state.subtotal += Number(action.payload.price);
        state.discountAmount = Number(
          (state.subtotal - state.total).toFixed(2)
        );
        state.total +=
          Number(action.payload.price) * (1 - state.discount / 100);
      } else {
        state.list.push({
          ...action.payload,
          quantity: 1,
        });
        state.subtotal += Number(action.payload.price);
        state.total +=
          Number(action.payload.price) * (1 - state.discount / 100);
      }
    },
    removeFromCart: (state, action) => {
      const inCart = state.list.find((item) => item.id === action.payload.id);

      if (inCart) {
        state.list.map((item) => {
          if (item.id === action.payload.id && item.quantity > 1) {
            if (item.quantity) {
              item.quantity -= 1;
            }
          } else if (item.id === action.payload.id && item.quantity === 1) {
            state.list.splice(state.list.indexOf(item), 1);
          }
          return item;
        }, state);
        state.subtotal -= Number(action.payload.price);
        state.discountAmount = Number(
          (state.subtotal - state.total).toFixed(2)
        );
        state.total -=
          Number(action.payload.price) * (1 - state.discount / 100);

        if (state.list.length === 0) {
          state.discount = 0;
          state.promoCode = "";
        }
      }
    },
    setDiscount: (state, action) => {
      if (state.list.length === 0) {
        state.discount = 0;
      } else {
        state.discount = action.payload;
      }
      const newTotal = state.subtotal * (1 - state.discount / 100);
      state.discountAmount = Number((state.subtotal - newTotal).toFixed(2));
      state.total = state.subtotal * (1 - state.discount / 100);
    },
    resetCart: (state) => {
      state.list = [];
      state.subtotal = 0;
      state.total = 0;
      state.discount = 0;
      state.promoCode = "";
      state.delivery = 0;
      state.discountAmount = 0;
    },
    setPromoCode: (state, action) => {
      state.promoCode = action.payload;
    },
  },
});

export const {
  addToCart,
  resetCart,
  setDiscount,
  setPromoCode,
  removeFromCart,
} = cartSlice.actions;

export default cartSlice.reducer;
