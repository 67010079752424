import * as React from "react";

export const RightArrowSvg = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={8} height={12} fill="none">
      <path
        stroke="#60708E"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M1.2 10.8 6 6 1.2 1.2"
      />
    </svg>
  );
};
