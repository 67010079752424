import React from "react";

import { hooks } from "../hooks";
import { theme } from "../constants";
import { actions } from "../store/actions";

export const ProductCounter = ({ item }) => {
  const dispatch = hooks.useAppDispatch();

  const btnStyle = {
    width: 30,
    height: 30,
    borderWidth: 1,
    borderRadius: 15,
    alignItems: "center",
    justifyContent: "center",
    borderColor: theme.colors.aliceBlue2,
  };

  const addToCartButton = () => {
    return (
      <button
        style={{ ...btnStyle }}
        onClick={() => {
          dispatch(actions.addToCart(item));
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={30}
          height={30}
          fill="none"
        >
          <rect
            width={29}
            height={29}
            x={0.5}
            y={0.5}
            stroke="#E8EFF4"
            rx={14.5}
          />
          <path
            stroke="#193364"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1.2}
            d="M14.955 10.916v8.167M10.898 15h8.114"
          />
        </svg>
      </button>
    );
  };

  const removeFromCartButton = () => {
    return (
      <button
        data-aos="fade"
        style={{ ...btnStyle }}
        onClick={() => {
          dispatch(actions.removeFromCart(item));
        }}
      >
        <svg width={30} height={30} fill="none">
          <rect
            width={29}
            height={29}
            x={0.5}
            y={0.5}
            stroke="#E8EFF4"
            rx={14.5}
          />
          <path
            stroke="#193364"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1.2}
            d="M11 15h8.114"
          />
        </svg>
      </button>
    );
  };

  const renderQty = () => {
    return (
      <span
        data-aos="fade"
        style={{
          ...theme.fonts.Mulish_400Regular,
          fontSize: 14,
          color: theme.colors.textColor,
          paddingTop: 4,
          paddingBottom: 4,
        }}
      >
        {item.quantity}
      </span>
    );
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
      }}
    >
      {addToCartButton()}
      {item.quantity > 0 ? renderQty() : null}
      {item.quantity > 0 ? removeFromCartButton() : null}
    </div>
  );
};
