export const flex = {
  row: {
    flexDirection: "row",
  },
  rowCenter: {
    flexDirection: "row",
    alignItems: "center",
  },
  rowCenterSpace: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  rowCenterSpaceAround: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-around",
  },
  rowCenterSpaceBetween: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  rowCenterSpaceEvenly: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-evenly",
  },
  rowCenterSpaceEvenlyWrap: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-evenly",
    flexWrap: "wrap",
  },
  rowCenterWrap: {
    flexDirection: "row",
    alignItems: "center",
    flexWrap: "wrap",
  },
  rowSpace: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  rowSpaceAround: {
    flexDirection: "row",
    justifyContent: "space-around",
  },
  rowSpaceBetween: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  rowSpaceEvenly: {
    flexDirection: "row",
    justifyContent: "space-evenly",
  },
  rowWrap: {
    flexDirection: "row",
    flexWrap: "wrap",
  },
  col: {
    flexDirection: "column",
  },
  colCenter: {
    flexDirection: "column",
    alignItems: "center",
  },
  colCenterSpace: {
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
  },
  colCenterSpaceAround: {
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-around",
  },
  colCenterSpaceBetween: {
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
  },
  colCenterSpaceEvenly: {
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-evenly",
  },
  colCenterSpaceEvenlyWrap: {
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-evenly",
    flexWrap: "wrap",
  },
};
