import React from "react";

import { theme } from "../constants";

export const BurgerMenuItem = ({
  titleLine1,
  titleLine2,
  titleLine3,
  icon,
}) => {
  return (
    <div
      style={{
        display: "flex",
        borderBottomWidth: 1,
        paddingLeft: 20,
        paddingRight: 20,
        flexDirection: "row",
        marginBottom: 20,
        paddingBottom: 26,
        borderBottomColor: `${theme.colors.lavenderMist}20`,
        borderBottomStyle: "solid",
        // alignItems: "center",
      }}
    >
      {icon}
      <div style={{ marginLeft: 8 }}>
        {titleLine1?.map((ele, index) => {
          return (
            <div
              key={index}
              style={{
                color: theme.colors.white,
                fontSize: 14,
                lineHeight: 1.5,
                ...theme.fonts.Mulish_400Regular,
                textAlign: "left",
              }}
            >
              {ele}
            </div>
          );
        })}
        <div
          style={{
            color: theme.colors.white,
            fontSize: 14,
            lineHeight: 1.5,
            ...theme.fonts.Mulish_400Regular,
            textAlign: "left",
          }}
        >
          {titleLine2}
        </div>
        <div
          style={{
            color: theme.colors.white,
            fontSize: 14,
            lineHeight: 1.5,
            ...theme.fonts.Mulish_400Regular,
            textAlign: "left",
          }}
        >
          {titleLine3}
        </div>
      </div>
    </div>
  );
};
