import { useRefresh } from "./useRefresh";
import { useNavigate } from "react-router-dom";
import { useWindowSize } from "./useWindowSize";
import { useViewability } from "./useViewability";
import { useDispatch, useSelector } from "react-redux";

export const useAppDispatch = () => useDispatch();
export const useAppSelector = useSelector;

export const useAppNavigate = () => useNavigate();

export const hooks = {
  useRefresh,
  useWindowSize,
  useAppDispatch,
  useAppSelector,
  useViewability,
  useAppNavigate,
};
