import { ProductSale } from "./ProductSale";
import { ProductPrice } from "./ProductPrice";
import { ProductRating } from "./ProductRating";
import { ProductInCart } from "./ProductInCart";
import { ProductCounter } from "./ProductCounter";
import { ProductInWishlist } from "./ProductInWishlist";
import { ProductCounterInner } from "./ProductCounterInner";

export const product = {
  ProductSale,
  ProductPrice,
  ProductInCart,
  ProductRating,
  ProductCounter,
  ProductInWishlist,
  ProductCounterInner,
};
