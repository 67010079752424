const Mulish_400Regular = { fontFamily: "Mulish-Regular" };
const Mulish_500Medium = { fontFamily: "Mulish-Medium" };
const Mulish_600SemiBold = { fontFamily: "Mulish-SemiBold" };
const Mulish_700Bold = { fontFamily: "Mulish-Bold" };
const Mulish_800ExtraBold = { fontFamily: "Mulish-ExtraBold" };
const Mulish_900Black = { fontFamily: "Mulish-Black" };

export const fonts = {
  Mulish_400Regular: { ...Mulish_400Regular },
  Mulish_500Medium: { ...Mulish_500Medium },
  Mulish_600SemiBold: { ...Mulish_600SemiBold },
  Mulish_700Bold: { ...Mulish_700Bold },
  Mulish_800ExtraBold: { ...Mulish_800ExtraBold },
  Mulish_900Black: { ...Mulish_900Black },
};

// $Mulish-Regular: 'Mulish-Regular';
// $Mulish-SemiBold: 'Mulish-SemiBold';
// $Mulish-Bold: 'Mulish-Bold';
// $Mulish-ExtraBold: 'Mulish-ExtraBold';
// $Mulish-Black: 'Mulish-Black';
