import axios from "axios";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { Carousel } from "react-responsive-carousel";
import { MAIN_URL, URLS } from "../../config";
import { hooks } from "../../hooks";
import { theme } from "../../constants";
import { product } from "../../product";
import { components } from "../../components";

export const Product = () => {
  const location = useLocation();
  const item = location.state.item;

  const dispatch = hooks.useAppDispatch();

  const navigate = useNavigate();

  const [reviewsData, setReviewsData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [selectedSize, setSelectedSize] = useState(item.sizes?.[1]);
  const [selectedColor, setSelectedColor] = useState(item.colors?.[1].name);

  const modifiedItem = {
    ...item,
    color: selectedColor,
    size: selectedSize,
  };

  const [activeIndex, setActiveIndex] = useState(0);

  const handleSlideChange = (index) => {
    setActiveIndex(index);
  };

  // const getReviews = async () => {
  //   setIsLoading(true);
  //   try {
  //     const reviews = await axios
  //       .get(URLS.GET_REVIEWS)
  //       .then((res) => res.data.reviews);

  //     setReviewsData(reviews);
  //   } catch (error) {
  //     console.error(error);
  //   } finally {
  //     setIsLoading(false);
  //   }
  // };

  useEffect(() => {
    window.scrollTo(0, 0);
    // getReviews();
  }, []);

  const renderHeader = () => {
    return <components.Header goBack={true} line={true} basket={true} />;
  };

  const renderCarousel = () => {
    return (
      <div style={{ marginBottom: 22 }}>
        {/* <Carousel
          infiniteLoop={false}
          showStatus={false}
          showThumbs={false}
          thumbWidth={22}
          showIndicators={false}
          showArrows={false}
          onChange={handleSlideChange}
          swipeable={true}
          emulateTouch={true}
        >
          {item.images?.map((item, index) => {
            return (
              <img
                key={index}
                src={item}
                alt="Carousel"
                style={{
                  width: "100%",
                  height: 350,
                  objectFit: "contain",
                  backgroundColor: theme.colors.imageBackground,
                }}
              />
            );
          })}
        </Carousel> */}
        <img
          src={`${MAIN_URL}/${item?.item_image}`}
          alt="Carousel"
          style={{
            width: "100%",
            height: 350,
            objectFit: "contain",
            backgroundColor: theme.colors.imageBackground,
          }}
        />
      </div>
    );
  };

  const renderIndicator = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          paddingBottom: 20,
          borderBottom: `1px solid ${theme.colors.aliceBlue2}`,
          marginBottom: 20,
        }}
      >
        {item.images?.map((item, index) => {
          const isSelected = index === activeIndex;
          const indicatorStyle = {
            background: isSelected ? theme.colors.mainColor : "#E8EFF4",
            display: "inline-block",
            width: 22,
            height: 6,
            margin: "0 5px",
            borderRadius: 6,
          };
          return <div style={indicatorStyle} key={index} />;
        })}
      </div>
    );
  };

  const renderNameWithButton = () => {
    return (
      <div
        style={{
          display: "flex",
          padding: "0 20px 0 20px",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <h3
          style={{
            ...theme.fonts.Mulish_700Bold,
            fontSize: 20,
            color: theme.colors.mainColor,
          }}
        >
          {item?.item_name}
        </h3>
      </div>
    );
  };

  const renderPriceWithQuantity = () => {
    return (
      <div
        style={{
          display: "flex",
          marginBottom: 20,
          padding: "0 20px 0 20px",
          ...theme.flex.rowCenterSpaceBetween,
        }}
      >
        <div
          style={{
            ...theme.fonts.Mulish_600SemiBold,
            fontSize: 20,
            lineHeight: 1.5,
            color: theme.colors.mainColor,
          }}
        >
          {item?.item_price}
        </div>
        <product.ProductCounterInner item={modifiedItem} />
      </div>
    );
  };

  const renderDescription = () => {
    return (
      <div style={{ marginBottom: 40, padding: "0 20px 0 20px" }}>
        <h5
          style={{
            marginBottom: 14,
            ...theme.fonts.Mulish_600SemiBold,
            fontSize: 16,
            fontWeight: 600,
            color: theme.colors.mainColor,
          }}
        >
          Description
        </h5>
        <p
          style={{
            margin: 0,
            marginBottom: 20,
            color: theme.colors.textColor,
            lineHeight: 1.7,
          }}
        >
          {item?.item_desc}
        </p>
      </div>
    );
  };

  const renderContent = () => {
    if (isLoading) return <components.Loader />;

    return (
      <div style={{ padding: "0 0 20px 0" }}>
        {renderCarousel()}
        {renderIndicator()}
        {renderNameWithButton()}
        {renderPriceWithQuantity()}
        {renderDescription()}
      </div>
    );
  };

  return (
    <>
      {renderHeader()}
      {renderContent()}
    </>
  );
};
