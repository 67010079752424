import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  selectedTags: [],
  selectedSizes: [],
  selectedColors: [],
  selectedRatings: [],
  selectedCategories: [],
};

export const filterSlice = createSlice({
  name: "filter",
  initialState,
  reducers: {
    setSelectedTags: (state, action) => {
      state.selectedTags = action.payload;
    },
    setSelectedColors: (state, action) => {
      state.selectedColors = action.payload;
    },
    setSelectedSizes: (state, action) => {
      state.selectedSizes = action.payload;
    },
    setSelectedRatings: (state, action) => {
      state.selectedRatings = action.payload;
    },
    setSelectedCategories: (state, action) => {
      state.selectedCategories = action.payload;
    },
    resetFilters: (state) => {
      state.selectedTags = [];
      state.selectedSizes = [];
      state.selectedColors = [];
      state.selectedRatings = [];
      state.selectedCategories = [];
    },
  },
});

export const {
  resetFilters,
  setSelectedTags,
  setSelectedSizes,
  setSelectedColors,
  setSelectedRatings,
  setSelectedCategories,
} = filterSlice.actions;
