import { InputField } from "./InputField";
import { ScrollView } from "./ScrollView";
import { InfiniteScrollR } from "./InfiniteScrollR";
import { ImageBackground } from "./ImageBackground";

export const custom = {
  InputField,
  ScrollView,
  InfiniteScrollR,
  ImageBackground,
};
