import React from "react";
import { hooks } from "../hooks";
import { custom } from "../custom";
import { product } from "../product";
import { theme } from "../constants";
import { MAIN_URL } from "../config";

export const ProductCard = ({ item, isLast }) => {
  const navigate = hooks.useAppNavigate();

  const renderImage = () => {
    return (
      <button
        onClick={() => navigate("/product", { state: { item } })}
        style={{ borderRadius: 12 }}
      >
        <custom.ImageBackground
          imageUrl={`${MAIN_URL}/${item?.item_image}`}
          style={{
            width: 100,
            height: "100%",
            borderRadius: 12,
            backgroundColor: theme.colors.imageBackground,
          }}
        >
          {/* <product.ProductSale item={item} style={{ margin: 10 }} /> */}
        </custom.ImageBackground>
      </button>
    );
  };

  const renderInfo = () => {
    return (
      <div
        style={{
          borderColor: theme.colors.antiFlashWhite,
          width: "100%",
          paddingRight: 0,
          flexDirection: "row",
          flex: 1,
          display: "flex",
          alignItems: "center",
        }}
      >
        <div
          style={{
            marginRight: "auto",
            paddingLeft: 14,
            display: "flex",
            flexDirection: "column",
            paddingTop: 2,
            paddingBottom: 2,
          }}
        >
          <h6
            style={{
              ...theme.fonts.Mulish_400Regular,
              fontSize: 14,
              lineHeight: 1.5,
              margin: 0,
              color: theme.colors.textColor,
              fontWeight: 400,
              marginBottom: 4,
            }}
          >
            {item?.item_name}
          </h6>
          <product.ProductPrice
            item={item}
            containerStyle={{ marginBottom: "auto" }}
          />
        </div>
        <product.ProductCounter item={item} />
      </div>
    );
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        height: 100,
        padding: "0 20px",
        marginBottom: isLast ? 20 : 14,
        paddingBottom: isLast ? 14 : 14,
        borderBottom: `1px solid ${theme.colors.lavenderMist}`,
      }}
    >
      {renderImage()}
      {renderInfo()}
    </div>
  );
};
