import { createSlice } from "@reduxjs/toolkit";

const initialState = { firstLaunch: true };

const firstLaunchSlice = createSlice({
  name: "firstLaunchSlice",
  initialState,
  reducers: {
    setFirstLaunch: (state, action) => {
      state.firstLaunch = action.payload;
    },
  },
});

export const { setFirstLaunch } = firstLaunchSlice.actions;

export { firstLaunchSlice };
