import React, { useEffect } from "react";

import { items } from "../../items";
import { custom } from "../../custom";
import { svg } from "../../assets/svg";
import { theme } from "../../constants";
import { components } from "../../components";
import { actions } from "../../store/actions";
import { hooks } from "../../hooks";

export const Order = () => {
  const dispatch = hooks.useAppDispatch();
  const navigate = hooks.useAppNavigate();

  const { subtotal, delivery, discount, list, total, discountAmount } =
    hooks.useAppSelector((state) => state.cartSlice);

  const calculateTotal = (cartData) => {
    return cartData?.reduce((total, item) => {
      return total + parseFloat(item?.item_price) * item?.quantity;
    }, 0);
  };

  const totalNew = calculateTotal(list);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const renderHeader = () => {
    return (
      <components.Header
        title="Order"
        burger={true}
        basket={true}
        line={true}
      />
    );
  };

  const renderProducts = () => {
    if (list.length > 0) {
      return (
        <div
          style={{
            marginBottom: 30,
            borderBottom: `1px solid ${theme.colors.lavenderMist}`,
          }}
        >
          {list?.map((item, index, array) => {
            const isLast = index === array.length - 1;
            return <items.OrderItem key={index} item={item} isLast={isLast} />;
          })}
        </div>
      );
    }

    return null;
  };

  const renderTotal = () => {
    if (list.length > 0) {
      return (
        <div
          style={{
            marginBottom: 20,
            padding: "0 20px",
          }}
        >
          {/* <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: 10,
            }}
          >
            <h5
              style={{
                margin: 0,
                ...theme.fonts.Mulish_600SemiBold,
                fontSize: 16,
                color: theme.colors.mainColor,
                lineHeight: 1.5,
              }}
            >
              Subtotal
            </h5>
            <span
              style={{
                margin: 0,
                ...theme.fonts.Mulish_600SemiBold,
                fontSize: 16,
                color: theme.colors.mainColor,
                lineHeight: 1.5,
              }}
            >
              {subtotal.toFixed(2).replace(".", ",")}
            </span>
          </div> */}
          {/* <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <h4
              style={{
                margin: 0,
                ...theme.fonts.Mulish_600SemiBold,
                fontSize: 18,
                color: theme.colors.mainColor,
                lineHeight: 1.5,
              }}
            >
              Total
            </h4>
            <span
              style={{
                margin: 0,
                ...theme.fonts.Mulish_600SemiBold,
                fontSize: 18,
                color: theme.colors.mainColor,
                lineHeight: 1.5,
              }}
            >
              {totalNew.toFixed(2).replace(".", ",")}
            </span>
          </div> */}
        </div>
      );
    }

    return null;
  };

  const renderButton = () => {
    return (
      <components.Button
        title="Enquiry Now"
        colorScheme="light"
        onClick={() => {
          navigate("/ShippingAndPaymentInfo");
        }}
        containerStyle={{ padding: "0 20px" }}
      />
    );
  };

  const renderContent = () => {
    return (
      <div style={{ paddingTop: 20, paddingBottom: 64 + 20 }}>
        {renderProducts()}
        {renderTotal()}
        {renderButton()}
      </div>
    );
  };

  return (
    <>
      {renderHeader()}
      {renderContent()}
    </>
  );
};
